import React from "react"
import "./Splash.css"
import Skills from "./Skills"
import Projects from "./Projects"
import Fun from "./Fun"
import Contact from "./Contact"
import Image from "./image.png"

class LandingPage extends React.Component {
  constructor(props) {
    super(constructor)
    this.state = {
      open: true,
      whoami_done: false,
      whoami_terminal_done: false,
      whoami_text_complete: false,
      age_done: false,
      location_done: false,
      occupation_done: false,
      employer_done: false,
      education_done: false,
      resume_done: false,
      rotated: false,
    }
    this.handleButton = this.handleButton.bind(this)
  }

  componentDidMount() {
    this.typewriterHelper("whoami", "DEREK@DAI:$", 100)
  }

  typewriterHelper(idName, text, timer) {
    let html_object = document.getElementById(idName)
    text = text.split("")

    text.forEach(() => {
      window.setTimeout(() => {
        html_object.innerHTML += text.shift()
        if (text.length === 0) {
          window.setTimeout(() => {
            this.setState({ whoami_terminal_done: true })
            document.getElementById("face").style.display = "block"
            document.getElementById("other_components").style.display = "block"
          }, 150)
        }

        if ((idName = "whoami" && text.length === 0)) {
          this.setState({ whoami_done: true })
        }
        if ((idName = "age" && text.length === 0)) {
          this.setState({ age_done: true })
        }
        if ((idName = "location" && text.length === 0)) {
          this.setState({ location_done: true })
        }
        if ((idName = "occupation" && text.length === 0)) {
          this.setState({ occupation_done: true })
        }
        if ((idName = "employer" && text.length === 0)) {
          this.setState({ employer_done: true })
        }
        if ((idName = "education" && text.length === 0)) {
          this.setState({ education_done: true })
        }
        if ((idName = "resume" && text.length === 0)) {
          this.setState({ resume_done: true })
        }
      }, timer)
      timer += Math.random() * 100 + 100
    })
  }

  countAge() {
    var age = 0
    let html_object = document.getElementById("age2")

    function incrementSeconds() {
      if (age === 24) {
        html_object.innerText = "24"
        return
      }
      age += 1
      html_object.innerText = age
    }

    setInterval(incrementSeconds, 250)
  }

  handleButton(e) {
    e.preventDefault()
    if (this.state.open) {
      document.getElementById("splash_wrapper").style.display = "none"
      this.setState({ open: false })
    } else {
      document.getElementById("splash_wrapper").style.display = "block"
      this.setState({ open: true })
    }
  }

  rotateMe() {
    document.getElementById("face").className = "face rotate-center "
    window.setTimeout(() => {
      document.getElementById("face").className = "face"
    }, 1500)
  }

  render() {
    if (this.state.whoami_terminal_done) {
      if (this.state.whoami_text_complete === false) {
        this.typewriterHelper("age", "AGE:", 100)
        this.typewriterHelper("location", "LOCATION:", 100)
        this.typewriterHelper("occupation", "OCCUPATION:", 100)
        this.typewriterHelper("education", "EDUCATION:", 100)
        this.typewriterHelper("employer", "EMPLOYER:", 100)
        this.typewriterHelper("resume", "RESUME:", 100)

        if (this.state.whoami_done === true) {
          this.typewriterHelper("whoamiPt2", "whoami", 100)
        }
        if (this.state.age_done === true) {
          // this.typewriterHelper("age2", "12", 300);
          this.countAge()
        }
        if (this.state.location_done === true) {
          this.typewriterHelper("location2", "NYC", 100)
        }
        if (this.state.occupation_done === true) {
          this.typewriterHelper("occupation2", "FULL_STACK_DEVELOPER", 100)
        }
        if (this.state.occupation_done === true) {
          this.typewriterHelper(
            "education2",
            `["UIUC_Mech_Engineer", "APP_ACADEMY"]`,
            100
          )
        }
        if (this.state.occupation_done === true) {
          this.typewriterHelper("employer2", "COULD_BE_YOU?", 100)
        }
        if (this.state.resume_done === true) {
          this.typewriterHelper("resume2", "> HERE <", 100)
        }
        this.setState({ whoami_text_complete: true })
      }
    }
    return (
      <div className="master">
        <button
          className="inline whoami"
          id="whoami"
          onClick={this.handleButton}
        />
        <button
          className="inline whoamiPt2"
          id="whoamiPt2"
          onClick={this.handleButton}
        />
        {/* <header className="inline" id="whoami" /> */}
        <div className="typeOut last inline header2">_</div>
        <section id="splash_wrapper">
          <div className="indent_one">
            <div className="typeOut text" id="age" />
            <div className="typeOut text" id="location" />
            <div className="typeOut text" id="occupation" />
            <div className="typeOut text" id="education" />
            <div className="typeOut text" id="employer" />
            <div className="typeOut text" id="resume" />
          </div>

          <div className="indent_two">
            <div className="typeOut text" id="age2" />
            <div className="typeOut text" id="location2" />
            <div
              className="typeOut text"
              id="occupation2"
              style={{ minWidth: "20vw" }}
            />
            <div
              className="typeOut text"
              id="education2"
              style={{ minWidth: "25vw" }}
            />
            <div className="typeOut text" id="employer2" />
            <div className="typeOut text vibrate-1">
              <a
                target="_blank"
                id="resume2"
                href="https://docs.google.com/document/d/1BWy0Ld4SdvBhWto_bAA7eHNnJoCiQcjpr_jeGoj12_c/edit?usp=drive_web&ouid=107022645519067348522"
              />
            </div>
          </div>
          <img
            id="face"
            src={Image}
            backgroundColor="transparent"
            className="face roll-in-right"
            onClick={this.rotateMe}
          />
        </section>

        <div id="other_components" class="other_components">
          <Skills />
          <Projects />
          <Fun />
          <Contact />
        </div>

        <div className="circle green"> </div>
        <div className="maximize">x</div>
        <div className="circle orange" />
        <div className="minimize">-</div>
        <div className="circle red" />
        <div className="escape">?</div>

        <br />
      </div>
    )
  }
}

export default LandingPage
