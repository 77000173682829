import React from "react"
import "./Projects.css"
import Hungr from "./hungr.gif"
import Tetris from "./tetris.gif"
import Polaris from "./polaris.gif"

class Projects extends React.Component {
  constructor(props) {
    super(constructor)
    this.state = {
      open: true,
      projects_terminal_done: false,
      projects_text_complete: false,
      polaris_done: false,
      hungr_done: false,
      loud_tetris_done: false,
      other_done: false,
    }
    this.handleButton = this.handleButton.bind(this)
  }

  componentDidMount() {
    window.setTimeout(() => {
      this.typewriterHelper("projects", "DEREK@DAI:$", 100)
    }, 4000)
  }

  typewriterHelper(idName, text, timer, delay = 0) {
    window.setTimeout(() => {
      let html_object = document.getElementById(idName)
      text = text.split("")

      text.forEach(() => {
        window.setTimeout(() => {
          html_object.innerHTML += text.shift()
          if (text.length === 0) {
            window.setTimeout(() => {
              this.setState({ projects_terminal_done: true })
            }, 1000)
          }
          if ((idName = "polaris" && text.length === 0)) {
            this.setState({ polaris_done: true })
          }
          if ((idName = "hungr" && text.length === 0)) {
            this.setState({ hungr_done: true })
          }
          if ((idName = "loud_tetris" && text.length === 0)) {
            this.setState({ loud_tetris_done: true })
          }
          if ((idName = "other" && text.length === 0)) {
            this.setState({ other_done: true })
          }
        }, timer)

        if (
          idName === "polaris2" ||
          idName === "hungr2" ||
          idName === "loud_tetris2" ||
          idName === "other2"
        ) {
          timer = timer + 15
        } else {
          timer += Math.random() * 100 + 30
        }
      })
    }, delay)
  }

  handleButton(e) {
    e.preventDefault()
    if (this.state.open) {
      document.getElementById("projects_wrapper").style.display = "none"
      this.setState({ open: false })
    } else {
      document.getElementById("projects_wrapper").style.display = "block"
      this.setState({ open: true })
    }
  }

  render() {
    if (this.state.projects_terminal_done) {
      if (this.state.projects_text_complete === false) {
        this.typewriterHelper("projectsPt2", "projects", 100)

        this.typewriterHelper("polaris", "POLARIS", 100)
        this.typewriterHelper("hungr", "> HUNGR <", 100)
        this.typewriterHelper("loud_tetris", "> LOUD_TETRIS <", 100)
        this.typewriterHelper("projects_other", "OTHER", 100)

        if (this.state.polaris_done === true) {
          // this.typewriterHelper("polaris1", "image_here", 100);
          document.getElementById("polaris1").style.display = "block"
          this.typewriterHelper(
            "polaris2",
            `"Production startup clinical trial search engine and patient data visualizer to connect healthcare professionals and patients with meaningful data"`,
            10
          )
        }
        if (this.state.hungr_done === true) {
          // this.typewriterHelper("hungr1", "image_here", 100);
          document.getElementById("hungr1").style.display = "block"
          this.typewriterHelper("hungr2", `Github`, 10)
          this.typewriterHelper(
            "hungr3",
            `"Blogging single page full stack web app about food, inspired by Tumblr, built with React / Redux, Ruby on Rails, PostgreSQL, and AWS"`,
            10
          )
        }
        if (this.state.loud_tetris_done === true) {
          // this.typewriterHelper("loud_tetris1", "image_here", 100);
          document.getElementById("loud_tetris1").style.display = "block"
          this.typewriterHelper("loud_tetris2", `Github`, 10)
          this.typewriterHelper(
            "loud_tetris3",
            `"Tetris, but loud, and played with your  V O I C E. Built with Web Speech API and vanilla JavaScript"`,
            10
          )
        }
        if (this.state.other_done === true) {
          this.typewriterHelper("projects_other1", "boy_and_tiger", 100)
          this.typewriterHelper("projects_other5", `Github`, 10)
          this.typewriterHelper(
            "projects_other3",
            `"Calvin and Hobbes comic book viewer built with MERN stack"`,
            10
          )
          this.typewriterHelper("projects_other6", `Github`, 10)

          this.typewriterHelper("projects_other2", "pokedex_hacker", 100)
          this.typewriterHelper(
            "projects_other4",
            `"App to identify effective counter types to a pokemon"`,
            10
          )
        }
        this.setState({ projects_text_complete: true })
      }
    }
    // polaris_done: false,
    //    hungr_done: false,
    //       loud_tetris_done: false,
    //          other_done: false

    return (
      <div className="projects_wrapper">
        <header className="typewriter">
          <button
            className="typewriter inline whoami"
            id="projects"
            onClick={this.handleButton}
          />
          <button
            className="typewriter inline whoamiPt2"
            id="projectsPt2"
            onClick={this.handleButton}
          />
        </header>

        <section id="projects_wrapper">
          {/* ---------- Polaris -------------- */}
          <div className="typeOut indent_one1 project_header" id="polaris" />
          <br />
          <div className="indent_one1">
            {/* <div className="typeOut text " id="polaris1" /> */}
            <img className="gif typeOut" src={Polaris} id="polaris1" />
          </div>
          <div className="indent_two1">
            <div className="typeOut text_description" id="polaris2" />
          </div>
          <br />
          {/* ---------- Hungr -------------- */}{" "}
          <a
            target="_blank"
            className="typeOut indent_one1 project_header"
            id="hungr"
            href="https://hung3r.herokuapp.com/#/"
          />
          <br />
          <a
            target="_blank"
            className="indent_one1"
            href="https://hung3r.herokuapp.com/#/"
          >
            {/* <div className="typeOut text " id="hungr1" /> */}
            <img className="gif" src={Hungr} id="hungr1" />
          </a>
          <div className="indent_two1">
            <a
              target="_blank"
              href="https://github.com/drkdi/hungr"
              className="typeOut text_description "
              id="hungr2"
            />
            <div className="typeOut text_description " id="hungr3" />
          </div>
          <br />
          {/* ---------- Loud Tetris -------------- */}
          <a
            target="_blank"
            className="typeOut project_header indent_one1"
            id="loud_tetris"
            href="https://derekdai.com/LoudTetris/"
          />
          <br />
          <a
            target="_blank"
            className="indent_one1"
            href="https://derekdai.com/LoudTetris/"
          >
            {/* <div className="typeOut text" id="loud_tetris1" /> */}
            <img className="gif" src={Tetris} id="loud_tetris1" />
          </a>
          <div className="indent_two1">
            <a
              target="_blank"
              href="https://github.com/drkdi/LoudTetris"
              className="typeOut text_description "
              id="loud_tetris2"
            />

            <div className="typeOut text_description" id="loud_tetris3" />
          </div>
          <br />
          {/* ---------- Other -------------- */}
          <div
            className="typeOut project_header indent_one1"
            id="projects_other"
          />
          <br />
          <div className="indent_one1 indent_one2 typeOut text">
            <a
              target="_blank"
              id="projects_other1"
              href="http://boyandtiger.herokuapp.com"
              className="inline"
            />
            <a
              target="_blank"
              href="https://github.com/maggiecs/BoyAndTiger/"
              className="typeOut text_description"
              id="projects_other5"
            />
            <div className="typeOut text_description " id="projects_other3" />

            <br />
            <a
              target="_blank"
              id="projects_other2"
              href="https://pokedex-hacker.herokuapp.com/"
              className="inline"
            />
            <a
              target="_blank"
              href="https://github.com/christineychan/pokedexHacker"
              className="typeOut text_description inline"
              id="projects_other6"
            />
            <div className="typeOut text_description " id="projects_other4" />
          </div>
        </section>
      </div>
    )
  }
}

export default Projects
