import React from "react"

class Contact extends React.Component {
  constructor(props) {
    super(constructor)
    this.state = {
      open: true,
      contact_terminal_done: false,
      contact_text_complete: false,
      github_done: false,
      email_done: false,
      linkedin_done: false,
      resume_done: false,
    }
    this.handleButton = this.handleButton.bind(this)
  }

  componentDidMount() {
    window.setTimeout(() => {
      this.typewriterHelper("contact", "DEREK@DAI:$", 100)
    }, 6000)
  }

  typewriterHelper(idName, text, timer) {
    let html_object = document.getElementById(idName)
    text = text.split("")

    text.forEach(() => {
      window.setTimeout(() => {
        html_object.innerHTML += text.shift()
        if (text.length === 0) {
          window.setTimeout(() => {
            this.setState({ contact_terminal_done: true })
          }, 150)
        }
        if ((idName = "github" && text.length === 0)) {
          this.setState({ github_done: true })
        }
        if ((idName = "email" && text.length === 0)) {
          this.setState({ email_done: true })
        }
        if ((idName = "linkedin" && text.length === 0)) {
          this.setState({ linkedin_done: true })
        }
        if ((idName = "contact_resume" && text.length === 0)) {
          this.setState({ resume_done: true })
        }
      }, timer)
      timer += Math.random() * 100 + 30
    })
  }

  handleButton(e) {
    e.preventDefault()
    if (this.state.open) {
      document.getElementById("contact_wrapper").style.display = "none"
      this.setState({ open: false })
    } else {
      document.getElementById("contact_wrapper").style.display = "block"
      this.setState({ open: true })
    }
  }

  render() {
    if (this.state.contact_terminal_done) {
      if (this.state.contact_text_complete === false) {
        this.typewriterHelper("contactPt2", "contact", 100)

        this.typewriterHelper("github", "GITHUB:", 100)
        this.typewriterHelper("email", "EMAIL:", 100)
        this.typewriterHelper("linkedin", "LINKEDIN:", 100)
        this.typewriterHelper("contact_resume", "RESUME:", 100)

        if (this.state.github_done === true) {
          this.typewriterHelper("github2", "@drkdi", 100)
        }
        if (this.state.email_done === true) {
          this.typewriterHelper("email2", "derekodai@gmail.com", 100)
        }
        if (this.state.linkedin_done === true) {
          this.typewriterHelper("linkedin2", "@daiderek", 100)
        }
        if (this.state.resume_done === true) {
          this.typewriterHelper("contact_resume2", "> HERE <", 100)
        }
        this.setState({ contact_text_complete: true })
      }
    }
    return (
      <div className="master">
        <header className="typewriter">
          <button
            className="typewriter inline whoami"
            id="contact"
            onClick={this.handleButton}
          />
          <button
            className="typewriter inline whoamiPt2"
            id="contactPt2"
            onClick={this.handleButton}
          />
        </header>

        <section id="contact_wrapper">
          <div className="indent_one">
            <div className="typeOut text" id="github" />
            <div className="typeOut text" id="email" />
            <div className="typeOut text" id="linkedin" />
            <div className="typeOut text" id="contact_resume" />
          </div>

          <div className="indent_two">
            <div className="typeOut text">
              <a
                target="_blank"
                id="github2"
                href="https://github.com/drkdi/"
              />
            </div>
            <div className="typeOut text">
              <a
                target="_blank"
                id="email2"
                href="mailto:derekodai@gmail.com"
              />
            </div>
            <div className="typeOut text">
              <a
                target="_blank"
                id="linkedin2"
                href="http://linkedin.com/in/daiderek/"
              />
            </div>
            <div className="typeOut text vibrate-1">
              <a
                target="_blank"
                id="contact_resume2"
                href="https://docs.google.com/document/d/1BWy0Ld4SdvBhWto_bAA7eHNnJoCiQcjpr_jeGoj12_c/edit?usp=drive_web&ouid=107022645519067348522"
              />
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default Contact
