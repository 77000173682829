import React from "react"
import "./Fun.css"

class Fun extends React.Component {
  constructor(props) {
    super(constructor)
    this.state = {
      open: true,
      fun_terminal_done: false,
      fun_text_complete: false,
      pics_done: false,
      tunes_done: false,
      movies_done: false,
      books_done: false,
      etc_done: false,
    }
    this.handleButton = this.handleButton.bind(this)
  }

  componentDidMount() {
    window.setTimeout(() => {
      this.typewriterHelper("fun", "DEREK@DAI:$", 100)
    }, 5000)
  }

  typewriterHelper(idName, text, timer) {
    let html_object = document.getElementById(idName)
    text = text.split("")

    text.forEach(() => {
      window.setTimeout(() => {
        html_object.innerHTML += text.shift()
        if (text.length === 0) {
          window.setTimeout(() => {
            this.setState({ fun_terminal_done: true })
          }, 150)
        }
        if ((idName = "pics" && text.length === 0)) {
          this.setState({ pics_done: true })
        }
        if ((idName = "tunes" && text.length === 0)) {
          this.setState({ tunes_done: true })
        }
        if ((idName = "movies" && text.length === 0)) {
          this.setState({ movies_done: true })
        }
        if ((idName = "books" && text.length === 0)) {
          this.setState({ books_done: true })
        }
        if ((idName = "etc" && text.length === 0)) {
          this.setState({ etc_done: true })
        }
      }, timer)
      timer += Math.random() * 100 + 30
    })
  }

  handleButton(e) {
    e.preventDefault()
    if (this.state.open) {
      document.getElementById("fun_wrapper").style.display = "none"
      this.setState({ open: false })
    } else {
      document.getElementById("fun_wrapper").style.display = "block"
      this.setState({ open: true })
    }
  }

  render() {
    if (this.state.fun_terminal_done) {
      if (this.state.fun_text_complete === false) {
        this.typewriterHelper("funPt2", "fun", 100)

        this.typewriterHelper("pics", "PICS:", 100)
        this.typewriterHelper("tunes", "TUNES:", 100)
        this.typewriterHelper("movies", "MOVIES:", 100)
        this.typewriterHelper("books", "BOOKS:", 100)
        this.typewriterHelper("etc", "ETC:", 100)

        if (this.state.pics_done === true) {
          // this.typewriterHelper("age2", "12", 300);
          this.typewriterHelper("pics2", "@derk", 100)
        }
        if (this.state.tunes_done === true) {
          this.typewriterHelper("tunes2", "@derek_dai", 100)
        }
        if (this.state.movies_done === true) {
          this.typewriterHelper(
            "movies2",
            "[“Blade Runner 2049”, “In The Mood For Love”, “Zima Blue”]",
            100
          )
        }
        if (this.state.books_done === true) {
          this.typewriterHelper(
            "books2",
            "[“The Count of Monte Cristo”, “Frankenstein”, “The Stranger”]",
            100
          )
        }
        if (this.state.etc_done === true) {
          this.typewriterHelper("etc2", "FAT_CATS", 100)
        }
        this.setState({ fun_text_complete: true })
      }
    }
    return (
      <div className="fun_wrapper">
        <header className="typewriter">
          <button
            className="typewriter inline whoami"
            id="fun"
            onClick={this.handleButton}
          />
          <button
            className="typewriter inline whoamiPt2"
            id="funPt2"
            onClick={this.handleButton}
          />
        </header>
        <section id="fun_wrapper">
          <div className="indent_one">
            <div className="typeOut text" id="pics" />
            <div className="typeOut text" id="tunes" />
            <div className="typeOut text" id="movies" />
            <div className="typeOut text" id="books" />
            <div className="typeOut text" id="etc" />
          </div>

          <div className="indent_two">
            <div className="typeOut text">
              <a id="pics2" href="https://www.instagram.com/d.erk/?hl=en" />
            </div>
            <div className="typeOut text">
              <a
                id="tunes2"
                href="https://open.spotify.com/user/darkxradiance?si=XNn9PeaaTWSLKhljhXmtCQ"
              />
            </div>
            <div
              className="typeOut text"
              id="movies2"
              style={{ minWidth: "40vw" }}
            />
            <div
              className="typeOut text"
              id="books2"
              style={{ minWidth: "40vw" }}
            />
            <div className="typeOut text">
              <a id="etc2" href="https://www.instagram.com/p/zA2VWty0qa/" />
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default Fun
