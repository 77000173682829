import React from "react"
import "./Skills.css"

class Skills extends React.Component {
  constructor(props) {
    super(constructor)
    this.state = {
      open: true,
      skills_terminal_done: false,
      skills_text_complete: false,
      languages_done: false,
      frameworks_done: false,
      other_done: false,
    }
    this.handleButton = this.handleButton.bind(this)
  }

  componentDidMount() {
    window.setTimeout(() => {
      this.typewriterHelper("skills", "DEREK@DAI:$", 100)
    }, 2500)
  }

  typewriterHelper(idName, text, timer, delay = 0) {
    window.setTimeout(() => {
      let html_object = document.getElementById(idName)
      text = text.split("")

      text.forEach(() => {
        window.setTimeout(() => {
          html_object.innerHTML += text.shift()
          if (text.length === 0) {
            window.setTimeout(() => {
              this.setState({ skills_terminal_done: true })
            }, 150)
          }
          if ((idName = "languages" && text.length === 0)) {
            this.setState({ languages_done: true })
          }
          if ((idName = "frameworks" && text.length === 0)) {
            this.setState({ frameworks_done: true })
          }
          if ((idName = "other" && text.length === 0)) {
            this.setState({ other_done: true })
          }
        }, timer)
        timer += Math.random() * 100 + 100
      })
    }, delay)
  }

  handleButton(e) {
    e.preventDefault()
    if (this.state.open) {
      document.getElementById("skills_wrapper").style.display = "none"
      this.setState({ open: false })
    } else {
      document.getElementById("skills_wrapper").style.display = "block"
      this.setState({ open: true })
    }
  }

  render() {
    if (this.state.skills_terminal_done) {
      if (this.state.skills_text_complete === false) {
        this.typewriterHelper("skillsPt2", "skills", 100)

        this.typewriterHelper("languages", "## LANGUAGES", 100)
        this.typewriterHelper("frameworks", "## FRAMEWORKS", 100, 1500)
        this.typewriterHelper("other", "## OTHER", 100, 2800)

        if (this.state.languages_done === true) {
          this.typewriterHelper("languages1", "~ JavaScript", 100)
          this.typewriterHelper("languages2", "~ Ruby", 100)
          this.typewriterHelper("languages3", "~ Python", 100)
          this.typewriterHelper("languages4", "~ Java", 100)
          this.typewriterHelper("languages5", "~ HTML", 100)
          this.typewriterHelper("languages6", "~ CSS", 100)
          this.typewriterHelper("languages7", "~ SQL", 100)
        }

        if (this.state.frameworks_done === true) {
          this.typewriterHelper("frameworks1", "~ React / Redux", 100, 1500)
          this.typewriterHelper("frameworks2", "~ Ruby on Rails", 100, 1500)
          this.typewriterHelper("frameworks3", "~ Python Flask", 100, 1500)
          this.typewriterHelper("frameworks4", "~ Express.js", 100, 1500)
          this.typewriterHelper("frameworks5", "~ Node.js", 100, 1500)
          this.typewriterHelper("frameworks6", "~ Gatsby.js", 100, 1500)
        }
        if (this.state.other_done === true) {
          this.typewriterHelper("other1", "~ PostgreSQL", 100, 2800)
          this.typewriterHelper("other2", "~ MongoDB", 100, 2800)
          this.typewriterHelper("other3", "~ Elasticsearch", 100, 2800)
          this.typewriterHelper("other4", "~ D3.js", 100, 2800)
          this.typewriterHelper("other5", "~ AWS", 100, 2800)
          this.typewriterHelper("other6", "~ Git", 100, 2800)
          this.typewriterHelper("other7", "~ Celery", 100, 2800)
          this.typewriterHelper("other8", "~ Redis", 100, 2800)
        }
        this.setState({ skills_text_complete: true })
      }
    }

    return (
      <div className="skills_wrapper">
        <header className="typewriter">
          <button
            className="typewriter inline whoami"
            id="skills"
            onClick={this.handleButton}
          />
          <button
            className="typewriter inline whoamiPt2"
            id="skillsPt2"
            onClick={this.handleButton}
          />
        </header>
        <section id="skills_wrapper" className="skills_body">
          {/* languages*/}
          <div className="indent_one">
            <div className="typeOut text header" id="languages" />
            <div className="typeOut text javascript" id="languages1" />
            <div className="typeOut text ruby" id="languages2" />
            <div className="typeOut text python" id="languages3" />
            <div className="typeOut text" id="languages4" />
            <div className="typeOut text" id="languages5" />
            <div className="typeOut text" id="languages6" />
            <div className="typeOut text" id="languages7" />
          </div>

          {/* frameworks */}

          <div className="indent_two">
            <div className="typeOut text header" id="frameworks" />
            <div className="typeOut text javascript" id="frameworks1" />
            <div className="typeOut text ruby" id="frameworks2" />
            <div className="typeOut text python" id="frameworks3" />
            <div className="typeOut text javascript" id="frameworks4" />
            <div className="typeOut text javascript" id="frameworks5" />
            <div className="typeOut text javascript" id="frameworks6" />
          </div>

          {/* other */}
          <div className="indent_three">
            <div className="typeOut text header" id="other" />
            <div className="typeOut text" id="other1" />
            <div className="typeOut text" id="other2" />
            <div className="typeOut text" id="other3" />
            <div className="typeOut text" id="other5" />
            <div className="typeOut text" id="other6" />
            <div className="typeOut text" id="other7" />
            <div className="typeOut text" id="other8" />
            <div className="typeOut text javascript" id="other4" />
          </div>
        </section>
      </div>
    )
  }
}

export default Skills
